<template>
  <div class="father">
    <div class="out-head" id="workPics" @mouseenter="clearTime()" @mouseleave="leave()">
      <div class="loading" v-if="loading">
        <span>拼命加载中.....</span>
      </div>
      <div class="item" v-else>
        <div v-if="workPicsList2.length==0" class="noPics">
          暂无图片.....
        </div>
        <div class="work-img-header" v-else v-viewer>
          <img v-for="(item,index) in workPicsList2" :key="index" v-lazy="baseUrl+item" :class="isBig?'work-img2':'work-img'">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "workPictures",
  props:['isBig'],
  data(){
    return{
      isChoose:false, //用来判断是否重新选择子菜单的项目 如果选择了，图片应该从头开始往下滑动
      workPicsList2:[],
      loading:true,
      timer:null, //定时器
      h:-1,  //计算滑动高度
      // baseUrl: process.env.VUE_APP_BASE_API,
      baseUrl:'http://hunanqunyinghui.oss-cn-guangzhou.aliyuncs.com/',
    }
  },
  created() {
    // this.getData();
  },
  mounted() {
    this.autoSroll("workPics");
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  watch:{
    // isChoose: {
    //   handler() {
    //     this.h = 0;
    //     document.getElementById("workPics").scrollTop = this.h + 1;
    //   },
    //   immediate: true
    // },
    isChoose(){
      this.h = 0;
      document.getElementById("workPics").scrollTop = this.h + 1;
    }
  },
  methods:{
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    autoSroll(Id) {
      this.timer =  setInterval(function() {
        //获取当前滚动条高度
        var current = document.getElementById(Id).scrollTop;
        if (current == this.h) {
          //滚动到底端,返回顶端
          this.h = 0;
          document.getElementById(Id).scrollTop = this.h + 1;
        } else {
          //以25ms/3.5px的速度滚动
          this.h = current;
          document.getElementById(Id).scrollTop = this.h + 1;
        }
      }, 50);
    },
    clearTime(){
      clearInterval(this.timer);
      this.timer = null;
    },
    leave(){
      this.autoSroll("workPics");
    }
  }
}
</script>

<style lang="less" scoped>
.father {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .out-head {
    width: 100%;
    height: 92%;
    margin-top: 6%;
    //padding: 0 0 10px 0;
    overflow-y: scroll;
    //加载中
    .loading{
      font-size:18px;
      color:#fff;
      width: 100%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }

    .item {
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      //justify-content: space-around;
      overflow: hidden;

      //暂无图片
      .noPics{
        font-size:18px;
        color:#fff;
        height: 100%;
        margin:30% auto;
        font-family: "华文细黑", Courier New, Courier, monospace;
      }

      .work-title {
        padding:3px 8px;
        width:auto;
        min-width: 100px;
        text-align: center;
        height: 18%;
        background-color: #0e3e9d;
        color: rgb(167, 198, 235);
        margin: 0 auto;
        font-size: 16px;
        font-family:"华文细黑",Courier New, Courier, monospace;
        margin-bottom: 3%;
      }

      .work-img-header {
        width: 100%;
        margin: 0 auto;
        height: 80%;
        flex-wrap: wrap;
        display: flex;
        align-items: center;
      }

      .work-img {
        width:90%;
        height:150px;
        margin-bottom: 18px;
        margin-left: 15px;
        object-fit: cover;
        display: inline-block;
      }
      .work-img2 {
        width:46%;
        height: 200px;
        margin-bottom: 18px;
        margin-left: 15px;
        object-fit: cover;
        display: inline-block;
      }
    }

    //.father::-webkit-scrollbar {
    //  display: none;
    //}
  }
}
//.father::-webkit-scrollbar {
//  display: none;

// 滚动条样式
.out-head::-webkit-scrollbar {/*滚动条整体样式*/
  width: 3px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.out-head::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #5689ee;
}
.out-head::-webkit-scrollbar-track {/*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  background: #151456;
}

</style>